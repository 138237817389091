<template>
  <div>
    <search-tool :title="$t('nav.clusterList')" @search="search" @refresh="getProviders(currentPage)">
      <template #handle-buttons>
        <el-button size="small" type="primary" @click="$router.push('/admin/cluster/add')">
          {{ $t("handle.add") }}
        </el-button>
      </template>
    </search-tool>

    <el-table :data="providers" v-loading="loading">
      <el-table-column label="#">
        <template slot-scope="scope">
          <div v-if="scope.row.kind == 'ProviderAWS'">
            <img :src="aws" />
          </div>
          <div v-if="scope.row.kind == 'ProviderALiCloud'">
            <img :src="aliyun" />
          </div>
          <div v-if="scope.row.kind == 'ProviderGCP'">
            <img :src="gcp" />
          </div>
          <div v-if="scope.row.kind == 'ProviderKubeconfig'">
            <img :src="onpremise" />
          </div>
          <div v-if="scope.row.kind == 'ProviderTencent'">
            <img :src="tencentcloud" />
          </div>
          <div v-if="scope.row.kind == 'ProviderHuawei'">
            <img :src="huaweicloud" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" sortable :label="$t('name')"> </el-table-column>
      <el-table-column prop="cluster" sortable :label="$t('Cluster')"> </el-table-column>
      <el-table-column prop="organizationUUID" :label="$t('belongOrganization')" width="400">
        <template slot-scope="">
          <div class="title">{{ organizationName }}</div>
          <div class="desc">{{ organization }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="regionID" :label="$t('region')"> </el-table-column>
      <el-table-column prop="kind" :label="$t('vendor')"> </el-table-column>
      <!-- <el-table-column prop="ready" :label="$t('status')" :filters="filters" :filter-method="filterReady">
        <template slot-scope="scope">
          <div class="status success" v-if="scope.row.ready">Ready</div>
          <div class="status danger" v-else>NotReady</div>
        </template>
      </el-table-column> -->

      <el-table-column width="100" :label="$t('handle.handle')">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <button class="table-row-handle">
              <i class="el-icon-more-outline"></i>
            </button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="editMonitor(scope.row)">
                  {{ $t("handle.editMonitor") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="edit(scope.row)">
                  {{ $t("handle.edit") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="deleteCluster(scope.row)">
                  {{ $t("handle.delete") }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>

    <el-dialog
      :title="$t('handle.edit')"
      :visible.sync="dialogVisible"
      top="30px"
      :close-on-click-modal="false"
      width="800px"
    >
      <cluster-form :form="form" type="list" ref="clusterForm" v-if="dialogVisible" />
      <span slot="footer">
        <el-button type="primary" @click="submit" :loading="updateLoading" size="small">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('handle.editMonitor')"
      :visible.sync="monitorDialogVisible"
      top="30px"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-button size="small" type="primary" style="margin-bottom: 10px;" @click="addMonitorItem">
        + {{ $t("addClusterMonitor") }}
      </el-button>

      <el-table :data="monitorList" v-loading="monitorLoading">
        <el-table-column label="#" width="140">
          <template slot-scope="scope">
            <img :src="prometheus" v-if="scope.row.kind === 'Prometheus'" />
            <img :src="netdata" v-if="scope.row.kind === 'Netdata'" />
          </template>
        </el-table-column>

        <el-table-column prop="host" :label="$t('monitorHost')"> </el-table-column>

        <el-table-column :label="$t('monitorReady')">
          <template slot-scope="scope">
            <div class="status success" v-if="scope.row.ready">ON</div>
            <div class="status danger" v-else>OFF</div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('handle.handle')">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="editMonitorItem(scope.row)">
              {{ $t("handle.edit") }}
            </el-button>

            <el-button type="danger" size="small" @click="deleteMonitorItem(scope.row)">
              {{ $t("handle.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :title="$t('handle.editMonitor')"
      :visible.sync="monitorFormDialogVisible"
      top="30px"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form :model="monitorForm" size="small" label-position="top" ref="monitorForm">
        <div class="list-title">
          <div class="title">
            基础信息设置
          </div>
        </div>

        <el-form-item
          :label="$t('monitorHost')"
          prop="host"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
        >
          <el-input v-model="monitorForm.host" placeholder="http://localhost:9090">
            <el-select v-model="monitorForm.kind" slot="prepend" style="width: 120px;">
              <el-option value="Prometheus" label="Prometheus"> </el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="Pod 别名">
          <el-input v-model="monitorForm.podAlias" placeholder="pod or pod_name"></el-input>
          <span class="help">
            用于监控数据过滤，多个 prometheus的版本采集的数据对元数据的处理不一样，存在 pod / pod_name 两种情况，
            避免数据查询失败，可根据实际情况填写，默认pod
          </span>
        </el-form-item>

        <el-form-item label="Node 别名">
          <el-input v-model="monitorForm.nodeAlias" placeholder="node or node_name"></el-input>
          <span class="help">
            用于监控数据过滤，多个prometheus的版本采集的数据对元数据的处理不一样，存在 node / node_name 两种情况，
            避免数据查询失败，可根据实际情况填写，默认node
          </span>
        </el-form-item>

        <el-form-item :label="$t('monitorReady')">
          <el-switch v-model="monitorForm.ready"> </el-switch>
        </el-form-item>

        <div class="list-title">
          <div class="title">
            Thanos设置
          </div>

          <div class="desc">
            当多个集群的监控数据被聚合后，为了区分监控数据来自于具体的集群，需要明确标识监控数据中的集群身份，数据来自于
            Prometheus 的 external_labels 字段
          </div>
        </div>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="唯一性标示 Key">
              <el-input v-model="monitorForm.monitorKey" placeholder="cluster"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="唯一性标示 Value">
              <el-input v-model="monitorForm.monitorValue" placeholder="dev"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="submitMonitorItem" :loading="submitMonitorLoading" size="small">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import prometheus from "@/assets/provider/prometheus.svg";
import netdata from "@/assets/provider/netdata.svg";
import provider from "@/mixins/provider";

import {
  providers,
  updateProvider,
  providerDelete,
  monitorList,
  updateMonitor,
  deleteMonitor,
  addMonitors
} from "api/app";

import moment from "moment";
import { cloneDeep } from "lodash";
import ClusterForm from "./Form.vue";

export default {
  components: { ClusterForm },
  mixins: [provider],

  data() {
    return {
      prometheus,
      netdata,
      providers: [],
      loading: false,
      dialogVisible: false,

      total: undefined,
      currentPage: 1,
      currentPageSize: 10,
      searchText: "",

      form: {
        name: "",
        kind: "",
        regionID: "",
        accessKey: "",
        secretAccessKey: "",
        kubeconfig: "",
        zoneID: "",
        cluster: "",
        gcpConfJson: "",
        status: "",
        account: "",
        accountPassword: "",
        domainName: "",
        projectID: "",
        projectName: ""
      },

      updateLoading: false,
      monitorDialogVisible: false,
      monitorList: [],
      submitMonitorLoading: false,
      monitorLoading: false,

      monitorFormDialogVisible: false,
      filters: [
        {
          text: "Ready",
          value: true
        },
        {
          text: "NotReady",
          value: false
        }
      ],

      currentProviderID: null,
      monitorType: "add",

      monitorForm: {}
    };
  },

  methods: {
    getProviders(page = 1, pageSize = this.currentPageSize, search = [this.searchText]) {
      this.loading = true;
      this.currentPage = page;

      providers(this.organization, { page, pageSize, search }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.providers = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    search(searchText) {
      this.searchText = searchText;
      this.getProviders(1);
    },

    currentChange(page) {
      this.currentPage = page;
      this.getProviders(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getProviders(1, size);
    },

    deleteCluster(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          providerDelete(item).then(response => {
            if (response.code === 0) {
              this.getProviders(this.currentPage);
            }
          });
        })
        .catch(() => {});
    },

    editMonitor(item) {
      this.monitorDialogVisible = true;
      this.currentProviderID = item.uuid;
      this.getMonitorList(item.uuid);
    },

    getMonitorList(id) {
      this.monitorLoading = true;
      monitorList(id).then(response => {
        if (response.code === 0) {
          this.monitorLoading = false;
          this.monitorList = response.data;
        }
      });
    },

    moment,

    edit(item) {
      this.dialogVisible = true;
      this.form = cloneDeep(item);
    },

    addMonitorItem() {
      this.monitorFormDialogVisible = true;
      this.monitorForm = {
        providerUUID: this.currentProviderID,
        organizationUUID: this.organization,
        host: "",
        ready: true,
        kind: "Prometheus",
        podAlias: "",
        nodeAlias: "",
        monitorKey: "",
        monitorValue: ""
      };

      this.monitorType = "add";
    },

    editMonitorItem(item) {
      this.monitorFormDialogVisible = true;
      this.monitorForm = cloneDeep(item);
      this.monitorType = "edit";
    },

    submitMonitorItem() {
      this.$refs["monitorForm"].validate(valid => {
        if (valid) {
          this.submitMonitorLoading = true;

          let action = this.monitorType == "add" ? addMonitors : updateMonitor;

          action(this.monitorForm).then(response => {
            this.submitMonitorLoading = false;
            if (response.code === 0) {
              this.monitorFormDialogVisible = false;
              this.getMonitorList(this.currentProviderID);
            }
          });
        }
      });
    },

    deleteMonitorItem(item) {
      this.$confirm(this.$t("delete", [item.host]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          deleteMonitor(item.uuid, this.$route.params.name).then(response => {
            if (response.code === 0) {
              this.getMonitorList(this.currentProviderID);
            }
          });
        })
        .catch(() => {});
    },

    submit() {
      this.$refs["clusterForm"].$refs["form"].validate(valid => {
        if (valid) {
          this.updateLoading = true;
          updateProvider(this.form).then(response => {
            this.updateLoading = false;
            if (response.code === 0) {
              this.dialogVisible = false;
              this.getProviders(this.currentPage);
            }
          });
        }
      });
    },

    filterReady(value, row) {
      return row.ready === value;
    }
  },

  mounted() {
    this.getProviders();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    },

    organizationName() {
      return this.$store.state.app.organizationName;
    }
  }
};
</script>
